import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { CartContext } from '../../context/Cart';
import { IoCartSharp, IoClose } from 'react-icons/io5';
import { placeOrder, RemoveCoupon, AddCustomerAddress, UpdateAddressDetails, ApplyCoupon} from '../../apollo/mutations';
import { ViewCart, QueryDefaultCustomerType } from "../../apollo/queries";
import { ThemeContext } from '../../context/Theme';
import Colors from '../../utils/colors/Colors';
import { LocationContext } from '../../context/Location';
import { useNavigate, useLocation ,UNSAFE_NavigationContext as NavigationContext} from 'react-router-dom';
import { useMutation, gql,useApolloClient , useQuery, useLazyQuery } from "@apollo/client";
import LandingPageLoading from '../../ui-components/LandingPageLoading/LandingPageLoading';
import UseCookie from '../../utils/cookies/useCookie';
import { toast } from 'react-toastify';
import { FaCrown } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import CheckOut from './CheckOut';
import CheckOrderStatus from '../../utils/CheckOrderStatus';
import { FaLocationDot } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import AdditionalAddressDetails from '../../ui-components/Address/AdditionalAddressDetails'
// import { LoginInfoContext } from '../../context/loginInfo';
import axios from 'axios';
import Loader from '../../ui-components/Loader/Loader';



const PLACEORDER = gql`
  ${placeOrder}
`;

const removeCoupon = gql`
  ${RemoveCoupon}
`;

const getDefaultCustomerType = gql`
${QueryDefaultCustomerType}
`;

const ADDCUSTOMERNEWADDRESS = gql`
  ${AddCustomerAddress}
`;

const UPDATEADDRESSDETAILS = gql`
  ${UpdateAddressDetails}
`;

const VIEWCART = gql`
  ${ViewCart}
`;

const applyCoupon = gql`
  ${ApplyCoupon}
`;

export default function Cart({setLoginDetails,openPremuimBanner,openSigninModal,closeCartDrawer,isChecked,selectAnotherLocation}) {

  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];  

  const {t} = useTranslation() 
  const client = useApolloClient();
  const history = React.useContext(NavigationContext);

  const [ApplyCoupon] = useMutation(applyCoupon, { onCompleted, onError });
  const [RemoveCoupon] = useMutation(removeCoupon, { onCompleted, onError });
  const [AddCustomerNewAddress] = useMutation(ADDCUSTOMERNEWADDRESS, { onCompleted, onError });
  const [UpdateAddressDetailsById] = useMutation(UPDATEADDRESSDETAILS, { onCompletedAddressUpdate, onErrorAddressUpdate });
  const [placeOrder] = useMutation(PLACEORDER, { onCompletedPlaceOrder, onErrorPlaceOrder });

  const { location, setLocation } = useContext(LocationContext)
  const { setCustomCookie, getCustomCookie, removeCustomCookie, clearAllCookies } = UseCookie();

  const { cartItems,addToCart,removeFromCart, setCartData } = useContext(CartContext)
  var [cartData, setCartDataItems] = useState([]);
  var [coupon, setCoupon] = useState("");
  var [totalCartItems, setTotalCartItems] = useState([]);
  var [totalDiscountPrice, setTotalDiscountPrice] = useState([]);
  var [totalActualPrice, setTotalActualPrice] = useState([]);
  var [refreshing, setRefreshing] = useState(false);
  var [isScrolling,setIsScrolling] = useState(false);
  var [isLocationServicable,setIsLocationServicable] = useState(false);
  var [isLoading,setIsLoading] = useState(false);
  // var [isLoading,setIsLoading] = useState(false);
  var [checkOutLoading,setCheckOutLoading] = useState(false);
  var [totalRecords,setTotalRecords] = useState(0);
  var [totalPages,setTotalPages] = useState(0);
  const navigateScreenTo = useNavigate();
  const {pathname} = useLocation();
  var [addressInfo,SetAddressInfo] = useState(false);
  var [placingOrder,setPlacingOrder] = useState(false)
  var [moreAddressDetails,setMoreAddressDetails] = useState(false)
  var [loginInfo,setLoginInfo] = useState()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('online');
  const [disabledOnlinePayment, setDisabledOnlinePayment] = useState(true);
  var [loadingCartDetails, setLoadingCartDetails] = useState(false)
  var [generalCustomerType, setGeneralCustomerType] = useState(false);
  var [defaultCustomerType,setDefaultCustomerType] = useState()

  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      Authorization: loginInfo?.jwtToken ? `Bearer ${loginInfo?.jwtToken}` : "",
      },
  });

  axiosInstance.defaults.maxRedirects = 0; // Set to 0 to prevent automatic redirects

    axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        console.log("🚀 ~ Cart ~ error 87 :", error)
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 302) {
          const redirectUrl = error.response.headers.location;
          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
        }
        return Promise.reject(error);
      }
    );

  // const [refetchCartData, { data: cartDetails, loading: loadingCartDetails, error }] = useLazyQuery(VIEWCART, {
  //   fetchPolicy: 'no-cache',
  //   // fetchPolicy: 'network-only',
  //   onCompleted: (fetchMoreResult) => {
  //     console.log("🚀 ~ App ~ fetchMoreResult:", fetchMoreResult);
  //     if (fetchMoreResult?.queryCart) {
  //       setCartData(fetchMoreResult.queryCart.cart, null, cartItems);
  //     }
  //   },
  //   onError: (error) => {
  //     console.error("🚀 ~ App ~ error:", error);
  //   }
  // });

  const callCartData = async (cartItems,loginInfo, locationInfo) => {
    // console.log("🚀 ~ callCartData ~ cartItems,loginInfo, locationInfo:", cartItems,loginInfo, locationInfo)
    if(loginInfo && Object.keys(loginInfo)?.length !=0){
      	try {
          let requestBody= {
            "longitude": ""+((locationInfo &&locationInfo?.longitude)?locationInfo?.longitude:"77.59104670126453"),
            "latitude": ""+((locationInfo && locationInfo?.latitude)?locationInfo?.latitude:"13.04269368886535"),
            }
          console.log("🚀 ~ callCartData ~ requestBody:", requestBody)
          // await refetchCartData({variables: requestBody,});
          loadingCartDetails = true
          setLoadingCartDetails(loadingCartDetails)
          const { data } = await client.query({
            query: VIEWCART,
            variables:requestBody,
            fetchPolicy: 'network-only',
          });
          generalCustomerType = await getCustomCookie('generalCustomerType')
          setGeneralCustomerType(generalCustomerType)
          if (data?.queryCart) {
            setCartData(data.queryCart.cart, null, cartItems);
          }
          loadingCartDetails = false
          setLoadingCartDetails(loadingCartDetails)
          //console.log("Cloud")
        } catch (error) {
          //console.log("🚀 ~ callCartData ~ error:", error)  
        }
    	}
    	else{
      	await setCartData(null,false,cartItems)
    	}
  	};


  async function onCompletedPlaceOrder(data) {
  }

  function onErrorPlaceOrder(errors) {
  }

  async function onCompletedAddressUpdate(data) {
  }
  function onErrorAddressUpdate(errors) {
  }

  async function onCompleted({ login }) {
  }
  function onError(errors) {
  }

  const handleCouponChange = (event) => {
    const value = event.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Allow only alphanumeric characters
    setCoupon(value);
  };

  const handleApplyCoupon = async() => {
    try{
      // isLoading = true
      // setIsLoading(isLoading)
        let requestBody = {code:coupon}
        let data = await ApplyCoupon({ variables:requestBody})
        console.log("🚀 ~ handleApplyCoupon ~ data:", data)
        if(data?.data?.applyCoupon){
          coupon = ""
          setCoupon(coupon)
          toast.success(`Coupon Applied`)
          reloadCartData()
        }
        else{
          toast.error(data?.errors?.message)
        }
        // isLoading = false
        // setIsLoading(isLoading)
    }
    catch(error){
      // isLoading = false
      // setIsLoading(isLoading)
      toast.error(`Failed to apply coupon `)
    }
  };

  const handleRemoveCoupon = async() => {
    try{
      // isLoading = true
      // setIsLoading(isLoading)
        let requestBody = {code:cartItems?.billDetails?.couponInfo?.code}
        let data = await RemoveCoupon({})
        console.log("🚀 ~ handleApplyCoupon ~ data:", data)
        if(data?.data?.removeAppliedCoupon){
          toast.success(`Coupon Removed`)
          reloadCartData()
        }
        else{
          toast.error(data?.errors?.message)
        }
        // isLoading = false
        // setIsLoading(isLoading)
    }
    catch(error){
      // isLoading = false
      // setIsLoading(isLoading)
      toast.error(`Failed to remove coupon `+error)
    }
  };

    
  useEffect(() => {
    if(isChecked){
      console.log("welcome karthik to cart",isChecked)
      refreshing = true
      setRefreshing(refreshing)
      isLoading = true
      setIsLoading(isLoading)
      OpenAddressDetailsModal(false)
      // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
      // cartData = []
      // setCartDataItems(cartData)
      reloadCartData()
    }
  },[isChecked])

  const reloadCartData = async ()=>{
    refreshing = true
    setRefreshing(refreshing)
    isLoading = true
    setIsLoading(isLoading)
    let loginInfo = await getCustomCookie('loginInfo')
    try{
      // console.log("welcome 1")
      await callCartData(cartItems,loginInfo,location)
      //console.log("welcome 2")
    }
    catch(error){
      //console.log("🚀 ~ reloadCartData ~ error:", error)
     }
    refreshing = false
    setRefreshing(refreshing)
    isLoading = false
    setIsLoading(isLoading)
  }

  // useEffect(() => {
  //   InitialLoad()
  // }, [ cartItems])
  
  useEffect(() => {
    if(cartItems?.items){
      //console.log("welcome",cartItems?.items.length)
      InitialLoad()
      fetchLoginInfo()
      if(cartItems?.billDetails?.grandTotal == 0){
        setDisabledOnlinePayment(true)
        setSelectedPaymentMethod('cod')
      }
      else{
        setDisabledOnlinePayment(false)
        setSelectedPaymentMethod('online')
      }
    }
  },[cartItems])

  useEffect(() => {
    if(isChecked){
      fetchLoginInfo()
    }
  },[isChecked])
  
  const fetchLoginInfo = async()=>{
      let isLocationServicable = await getCustomCookie('isLocationServicable') 
      setIsLocationServicable(isLocationServicable)
      loginInfo = await getCustomCookie('loginInfo')
      setLoginInfo(loginInfo)
      // let defaultCustomerType1 =  await getCustomCookie('defaultCustomerType') // if not login we use the default customer type that come from homepage api 
      const { data } = await client.query({
        query: getDefaultCustomerType,
        variables:{
          "longitude": "77.59104670126453",
          "latitude": "13.04269368886535",
        },
        fetchPolicy: 'network-only',
      });
      let defaultCustomerType = data?.queryDefaultCustomerType?.customerType // if not login we use the default customer type 
      setDefaultCustomerType(defaultCustomerType)
    }
    
   const InitialLoad = async ()=>{
    // await reloadCartData()
    cartData = cartItems?.items
    setCartDataItems(cartData)
    // totalCartItems = cartData?.reduce((noTotalItems,item)=>noTotalItems+item?.quantity,0)
    totalCartItems = cartData?.length
    setTotalCartItems(totalCartItems)
    // totalDiscountPrice = cartItems?.billDetails?cartItems?.billDetails?.grandTotal:cartData?cartData?.reduce((totalPrice,item)=>totalPrice+item?.quantity*parseInt(item?.sellingPrice),0):0
    // totalDiscountPrice = cartData?.reduce((totalPrice,item)=>totalPrice+item?.quantity*parseInt(item?.sellingPrice),0)
    // setTotalDiscountPrice(totalDiscountPrice)
    totalDiscountPriceCalculation()
    // totalActualPrice = cartItems?.billDetails?cartItems?.billDetails?.itemsMrp:cartData?cartData?.reduce((totalPrice,item)=>totalPrice+item?.quantity*parseInt(item?.mrp),0):0
    totalActualPrice = cartData?.reduce((totalPrice,item)=>totalPrice+item?.quantity*parseInt(item?.mrp),0)
    setTotalActualPrice(totalActualPrice)
    refreshing = false
    setRefreshing(refreshing)
    isLoading = false
    setIsLoading(isLoading)
   }

   const totalDiscountPriceCalculation = async ()=>{
    let totalDiscountPrice = 0
    let loginInfo = await getCustomCookie('loginInfo')
    for(let index=0;index<cartData?.length;index++){
      let item = cartData[index]
      let priceDetailOfAllCustomerTypes = await priceDetailAsObject(item?.prices);
      totalDiscountPrice =  totalDiscountPrice+item?.quantity*Number(priceDetailOfAllCustomerTypes[loginInfo?.customerType!=undefined?loginInfo?.customerType:0])
    }
    setTotalDiscountPrice(totalDiscountPrice)
   }
  //  const priceDetailAsObject = (prices) => {
  //   let data =  prices?.reduce((priceDetailOfAllCustomerTypes, { customerType, sellingPrice })=>{priceDetailOfAllCustomerTypes[customerType] = sellingPrice;return priceDetailOfAllCustomerTypes},{})
  //   return data
  // }

    //Only works when customer is a general or With skip login
    const getPremuimPrice = (AllCustomerTypes, generalCustomerType) => {
      for (let customerType in AllCustomerTypes) {
        if (customerType != generalCustomerType) {
          return AllCustomerTypes[customerType];
        }
      }
      return null; // or some other default value or action if no other key exists
    };

  const priceDetailAsObject = (prices) => {
    let data =  prices?.reduce((priceDetailOfAllCustomerTypes, { customerType, sellingPrice })=>{priceDetailOfAllCustomerTypes[customerType] = sellingPrice;return priceDetailOfAllCustomerTypes},{})
    return data
  }
 
  const handleRemoveFromCart = async(e,item,index) => {
    e.stopPropagation();
    console.log("🚀 ~ handleRemoveFromCart ~ e,item,index:", e,item,index)
    let isLocationServicable = await getCustomCookie('isLocationServicable') 
    let loginInfo = await getCustomCookie('loginInfo')
    let locationInfo = await getCustomCookie('locationInfo')
    await removeFromCart(item,index,cartItems,isLocationServicable, loginInfo, locationInfo);
    await callCartData(cartItems,loginInfo,locationInfo)
    setCheckOutLoading(true)
  }
  
  const handleAddToCart  = async(e,item,index) => {
    console.log("🚀 ~ handleAddToCart ~ e,item,index:", e,item,index)
    e.stopPropagation();
    let isLocationServicable = await getCustomCookie('isLocationServicable') 
    let loginInfo = await getCustomCookie('loginInfo')
    let locationInfo = await getCustomCookie('locationInfo')
    await addToCart(item,index,cartItems,isLocationServicable,loginInfo, locationInfo);
    await callCartData(cartItems,loginInfo,locationInfo)
    setCheckOutLoading(true)
  }

  const CartItemCards = ({item, index}) => {
    let customerType = loginInfo?.customerType!=undefined?loginInfo?.customerType:0
    // Use useMemo to memoize the result of the expensive calculation
    const priceDetailOfAllCustomerTypes = useMemo(() => priceDetailAsObject(item?.prices), [item]);
    return <>
      <div  className={`relative flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded cursor-pointer border border-appThemeColorAboveLight`} 
        style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}}
        onClick={()=> navigateScreenTo(`/productDetails/${item?.itemId || item?.productId}`,{state:item})}>
        <div className=" relative h-14 sm:h-14 md:h-18 lg:h-20 xl-28 w-1/4 overflow-hidden">
          <img src={item?.image} className="w-full h-full rounded object-contain" alt="Item" />
          {item?.outOfStock && <div className="absolute bottom-0 w-full flex justify-center">
            <span className="w-full bg-white bg-opacity-40 backdrop-blur-sm text-xxs sm:text-xxs md:text-xs lg:text-xs xl:text-xs lg:font-bold xl:font-bold text-red-500 text-center p-1">
              {t('OutofStock')}
            </span>
          </div>}
        </div>

        <div className='w-full mx-2'>
          <div className="text-left "> {/* Add sm:mb-0 to make sure it aligns correctly on small screens */}
            {item?.mrp!=priceDetailOfAllCustomerTypes[customerType] && <span className={`text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm line-through text-${colors?.heading}`}>₹{item?.mrp}</span>}
            <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md  mr-1 font-bold text-${colors?.heading}`}> ₹{priceDetailOfAllCustomerTypes[customerType!=undefined?customerType:defaultCustomerType]}</span>
          </div>
          <div className="text-left "> {/* Add sm:mb-0 to make sure it aligns correctly on small screens */}
            <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{item?.name}</span>
            {/* <div className=" text-md sm:text-sm md:text-md lg:text-md xl:text-md mr-1">{item?.quantity}</div> */}
          </div>

          {(cartItems?.cartobj && cartItems?.cartobj?.[item?.itemId ?? item?.productId]?.quantity) &&
          <div className='grid w-full '>
            <div className="flex flex-row justify-self-end w-16 bg-appThemeColorDark text-white text-center justify-around items-center rounded mt-1 sm:mt-0 mx-2">
              <button className={`pl-1 text-white text-md font-bold opacity-100 cursor-pointer transition duration-200 transform active:scale-50`} onClick={(e)=>handleRemoveFromCart(e,item,index)}>-</button>
              <text className="text-white text-xs sm:text-xs md:text-sm  lg:text-sm  xl:text-sm  font-bold">{cartItems['cartobj'][item?.itemId ?? item?.productId]?.quantity}</text>
              <button disabled={item?.outOfStock} className={`pr-1 text-white text-md font-bold ${(item?.outOfStock)?'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer transition duration-200 transform active:scale-50'}`} onClick={(e)=>handleAddToCart(e,item,index)}>+</button>
            </div>
          </div>}
        </div>
        {/* {console.log(priceDetailOfAllCustomerTypes[1]!=0)} */}
        {typeof generalCustomerType != 'number'&& priceDetailOfAllCustomerTypes && getPremuimPrice(priceDetailOfAllCustomerTypes,customerType)!=0 &&(
            <button onClick={(e) =>{e.stopPropagation();openPremuimBanner(true)}} className="absolute top-0 right-0 bg-gradient-to-r from-yellow-600 via-yellow-400 to-yellow-600 text-yellow-900 rounded p-1 hover:opacity-90 focus:outline-none transition duration-200 transform active:scale-90">
              <div className="flex justify-around items-center">
                <FaCrown className="text-xxs sm:text-xxs md:text-sm  lg:text-sm  xl:text-sm text-yellow-900" />
                <text className="text-xxxs sm:text-xxs md:text-xs  lg:text-sm  xl:text-sm  lg:font-bold xl:font-bold px-1 font-bold">Get for ₹{priceDetailOfAllCustomerTypes[1]}</text>
                <MdKeyboardArrowDown className="text-sm sm:text-sm md:text-sm  lg:text-lg  xl:text-lg  text-yellow-900 pt-0.5" />
              </div>
            </button>
          )}
      </div>
    </>
}

const placeOrderToCheckOut = async ()=>{
  if(loginInfo && Object.keys(loginInfo)?.length !=0){
   navigateScreenTo('/CheckOut')
  }
  else{
    await removeCustomCookie('skipLogin')
   navigateScreenTo('/SignIn')
  }
}

const mutateNewAddress = async (data) => {
  let requestBody = {
    "addressInput": data
  }
  try{
    let response = await AddCustomerNewAddress({ variables:requestBody})
    if(response.data){
      return response?.data?.addCustomerAddress
    }
    else{
      return false
    }
 }catch(e){return false}
}

const addNewAddress = async(label,aditionalInfo) => {
    let locationDetails = {
      "deliveryAddress": location?.deliveryAddress,
      "details": aditionalInfo?aditionalInfo:"",
      "label": label?label:"",
      "longitude": ""+location.longitude,
      "latitude": ""+location.latitude,
    }
    let response = await mutateNewAddress(locationDetails)
    if(response){
      setLocation({...locationDetails,_id:response?.address?._id})
      await setCustomCookie('addressInfo', JSON.stringify(response?.address))
    }
    else{
      toast('Failed To Save');
    }
}

const OpenAddressDetailsModal = (value,loginInfo)=>{
  if(loginInfo){setLoginDetails(loginInfo)}
  moreAddressDetails = value
  setMoreAddressDetails(moreAddressDetails)
}

const addressDataNavigation = ()=>{
  if(location?.deliveryAddress){
      if(isLocationServicable){ 
        OpenAddressDetailsModal(true)
      }
      else{
        toast(
          "Not Servicable Location !",
          'Service not available for the selected location. Please change the location.',
        )
      }
  }
  else{
    selectAnotherLocation(true);
    closeCartDrawer()
  }
}

const confirmOrder = async()=>{
  placingOrder = true
  setPlacingOrder(placingOrder)
  if(loginInfo && Object.keys(loginInfo)?.length !=0){
    let requestBody = {
      "paymentMethod": selectedPaymentMethod == 'cod'? 0: 1, // for cash on delivery
      "itemsTotal": cartItems?.items?.length>0?cartItems?.items?.length:0,
      "grandTotal": cartItems?.billDetails?.grandTotal
    }
    try{
      let data = await placeOrder({ variables:requestBody})

      if(selectedPaymentMethod != 'cod'){
        const params = new URLSearchParams({
            orderId: data?.data?.placeOrder?._id,
            amount: data?.data?.placeOrder?.billDetails?.grandTotal,
        });
        try {
          await setCustomCookie('checkOrderDetailsStatus', JSON.stringify({orderId: data?.data?.placeOrder?._id,}))
          window.history.pushState({ },'',`/Order/OrderDetails/${data?.data?.placeOrder?._id?.toString()}`);
          window.location.href  = `${process.env.REACT_APP_SERVER_URL}/phonepe/payment?${params.toString()}`
        } catch (error) {
          console.log("🚀 ~ confirmOrder ~ error:", error)          
        }
      }
    
      if(data?.data?.placeOrder?.orderStatus < 8){ //order confirmed
        setCartData()
        closeCartDrawer()
        toast.success(` #${data?.data?.placeOrder?._id?.slice(-5)} Order ${CheckOrderStatus(data?.data?.placeOrder?.orderStatus)}`)
      }
      else{
        toast.error(` #${data?.data?.placeOrder?._id?.slice(-5)} Order ${CheckOrderStatus(data?.data?.placeOrder?.orderStatus)}`)
      }
      placingOrder = false
      setPlacingOrder(placingOrder)
      // navigateScreenTo('/OrderTrackStatus',data?.data?.placeOrder)
    }catch(e){
    }
  }
  else{
      openSigninModal()
  }
}


  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };



  return (
    <div className='bg-cardBackgroundWS mt-2p pb-10 overflow-y-scroll hide-scroll-bar max-h-[85vh] sm:max-h-[90vh] md:max-h-[90vh] lg:max-h-[90vh] xl:max-h-[90vh]'>
      {isLoading ?
          <LandingPageLoading/>:
      !moreAddressDetails?<div >
        <div className=''>
          <div className='overflow-y-auto'>
          {
            cartData.map((item,index) => (
              <div key={`_${index}cartData`}><CartItemCards index={index} item={item} /></div>
            ))
          }
          </div>
          {cartData.length>0 &&<>
            <>
              <div className="text-left "> 
                    <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{t('Address') + (location?.label?" (" +location?.label + ")":"")}</span>
              </div>
              <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border border-appThemeColorAboveLight relative flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded cursor-pointer text-left`} onClick={()=>{selectAnotherLocation(true);closeCartDrawer()}}>
                  <span className={`text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{(location?.details?(location?.details + ", "):"") +(location?.deliveryAddress?location?.deliveryAddress:'No Address')}</span>
              </div>
            </>
            <>
              <div className="text-left "> 
                    <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{t('PaymentMethod')}</span>
              </div>
              <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border border-appThemeColorAboveLight relative flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                <div className="flex flex-col space-y-4 text-sm">
                  <label className={`flex items-center cursor-pointer`}>
                    <input
                      type="radio"
                      value="cod"
                      checked={selectedPaymentMethod === 'cod'}
                      onChange={handlePaymentMethodChange}
                      className={`form-radio h-4 w-4 text-green-600 checked:bg-green-600 focus:bg-green-600 focus:ring-0 transition duration-100 ease-in-out cursor-pointer`}
                    />
                    <span className="ml-2 text-gray-700">{t('cod')}</span>
                  </label>
                  <label className={`flex items-center ${disabledOnlinePayment?'cursor-not-allowed':'cursor-pointer'}`}>
                    <input
                      type="radio"
                      value="online"
                      disabled={disabledOnlinePayment}
                      checked={selectedPaymentMethod === 'online'}
                      onChange={handlePaymentMethodChange}
                      className={`form-radio h-4 w-4 text-green-600 checked:bg-green-600 focus:bg-green-600 focus:ring-0 transition duration-100 ease-in-out ${disabledOnlinePayment?'cursor-not-allowed':'cursor-pointer'}`}
                    />
                    <span className="ml-2 text-gray-700">{t('online')}</span>
                  </label>
                </div>          
              </div>
            </>
            {/* Offers Applied */}
            {(cartItems?.billDetails?.nestInfo?.applied || cartItems?.billDetails?.walletInfo?.applied ) && <>
              <div className="flex flex-col gap-1 mb-3"> 
                <span className={`text-left text-sm md:text-md font-bold text-${colors?.heading}`}>{t('OffersApplied')}</span>
                {cartItems?.billDetails?.nestInfo?.applied && <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border border-appThemeColorAboveLight relative flex p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                  <span className={`text-left text-xs md:text-sm font-normal text-${colors?.heading}`}>{cartItems?.billDetails?.nestInfo?.message}</span>
                </div>}
                {cartItems?.billDetails?.walletInfo?.applied && <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border border-appThemeColorAboveLight relative flex p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                  <span className={`text-left text-xs md:text-sm font-normal text-${colors?.heading}`}>{cartItems?.billDetails?.walletInfo?.message}</span>
                </div>}
                {/* {cartItems?.billDetails?.couponInfo?.applied && <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`relative flex p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded cursor-pointer text-left`} >
                  <span className={`text-left text-xs md:text-sm font-normal text-${colors?.heading}`}>{cartItems?.billDetails?.couponInfo?.message}</span>
                </div>} */}
              </div>
            </>}
            <>
              <div className="flex flex-col gap-1 mb-3"> 
                <span className={`text-left text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{t('applyCoupon')}</span>
                { cartItems?.billDetails?.couponInfo?.applied?<> 
                    <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border border-appThemeColorAboveLight flex flex-col gap-1 relative p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                      <div className={`text-left text-xs md:text-sm font-bold text-${colors?.heading}`}>{cartItems?.billDetails?.couponInfo?.code}</div>
                      <span className={`text-left text-xxs md:text-xs font-normal text-${colors?.heading}`}>{cartItems?.billDetails?.couponInfo?.message}</span>
                      <span className={`text-left text-xxs md:text-xs font-normal text-${colors?.heading}`}>You saved <span className='text-appThemeColorVeryDark text-xs md:text-sm text-bold'>Rs.{cartItems?.billDetails?.couponInfo?.amount}</span> using this coupon</span>
                      <IoClose
                        onClick={handleRemoveCoupon}
                        className={`absolute right-2 top-2 h-4 sm:h-4 md:h-4 lg:h-6 w-4 sm:w-4 md:w-4 lg:w-6 transition transform duration-200 active:scale-50 text-black-400 cursor-pointer text-${colors?.heading}`}
                        aria-hidden="true"
                      />
                      {/* <div className='absolute right-0 top-0 bg-white border rounded-lg border-appThemeColorDark p-2'>
                        Remove Coupon
                      </div> */}
                  </div>
                </> :
                <div className='font-sans flex flex-row mb-3'>
                    <input type="text" value={coupon} onChange={handleCouponChange} className={`text-black placeholder:text-black px-4 md:px-4 w-3/4 rounded rounded-r-none border border-appThemeColorVeryDark block text-xs md:text-sm font-normal outline-none focus:outline-none focus:border-appThemeColorVeryDark appearance-none focus:ring-0 placeholder:tracking-normal placeholder:font-normal`} placeholder="Enter Coupon Code" required />
                    <div className='flex flex-col text-center w-1/4 h-full items-center justify-center rounded rounded-l-none border border-l-0 border-appThemeColorVeryDark  overflow-hidden bg-appThemeColorLight '>
                        <button disabled={!coupon} onClick={handleApplyCoupon} className={`w-full py-3 xl:py-3 text-xs sm:text-xs md:text-xs lg:text-sm xl:text-sm leading-6 text-black transition duration-200 transform font-bold ${coupon?"cursor-pointer active:scale-95":"cursor-not-allowed opacity-50"}`}>
                          {t('APPLY')}
                        </button>
                    </div>
                </div>}
              </div>
            </>
            <CheckOut priceDetailAsObject={priceDetailAsObject} customerType={loginInfo?.customerType!=undefined?loginInfo?.customerType:defaultCustomerType} isLoading={checkOutLoading} reloadCartData={reloadCartData} setIsLoading={setCheckOutLoading} />
          </>}
          
          {
          cartData.length == 0 && 
                <div className="flex h-96 items-center justify-center">
                  {loadingCartDetails ? (
                    !refreshing && (
                      <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10"></div>
                    )
                  ) : (
                    <p className="text-center">No Items</p>
                  )}
                </div>
              }
        </div>
        {placingOrder && <Loader loading={placingOrder} />}
        {cartData.length != 0 && <div className=' cursor-pointer fixed bottom-0 left-0 w-full flex'>
          <button className="w-full bg-appThemeColorVeryDark text-white flex justify-between items-center rounded mt-1 sm:mt-0 p-3p m-2" onClick={()=>{(loginInfo && Object.keys(loginInfo)?.length !=0)?(location?.label?confirmOrder():addressDataNavigation()):confirmOrder()}}>
            {loginInfo && Object.keys(loginInfo)?.length !== 0 ?(
              location?.label?<div className='flex justify-between w-full'>
                <div className='flex flex-wrap items-center'>
                  <IoCartSharp size={20} />
                  <span className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mx-1">{t('placeOrder')} ({totalCartItems})</span>
                </div>
                <div className='flex items-end'>
                  {totalActualPrice!=totalDiscountPrice &&<span className="text-sm line-through">₹{cartItems?.billDetails?(parseInt(cartItems?.billDetails?.totalSaving)+parseInt(cartItems?.billDetails?.grandTotal)):totalActualPrice} </span>}
                  <span className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mx-1">₹{cartItems?.billDetails?cartItems?.billDetails?.grandTotal:totalDiscountPrice} </span>
                </div>
              </div>:
              <div className='flex justify-between w-full'>
                <div className='flex flex-wrap items-center'>
                  <FaLocationDot size={20} />
                  <span className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mx-1">{location?.deliveryAddress?t('addAdditionalAddress'):t('addAddress')}</span>
                </div>
              </div>
            ) : (
              <div className='flex flex-wrap items-center justify-center w-full' >
                <span className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mx-1">Login to proceed</span>
              </div>
            )}
          </button>
        </div>}
      </div>:
      <AdditionalAddressDetails location={location} addNewAddress={addNewAddress} colors={colors} moreAddressDetails={moreAddressDetails} OpenAddressDetailsModal={OpenAddressDetailsModal} />}
  </div>
  )
}
